import Button from "../../Button";
import constants from "../../../constants";
import InfoSvg from "../../../Svg/InfoSvg";
import Accordion from "@mui/material/Accordion";
import IconButton from "@mui/material/IconButton";
import { getDataFromStorage } from "../../../util";
import ProctorEyeSvg from "../../../Svg/ProctorEyeSvg";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { useReactMediaRecorder } from "react-media-recorder";
import NoProctoringIcon from "../../../Svg/NoProctoringIcon";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import StudentTestQuestionBox from "./StudentTestQuestionBox";
import StudentTestVideoPreview from "./StudentTestVideoPreview";
import React, { useContext, useEffect, useRef, useState } from "react";
import useUploadLargeFileToS3 from "../../../Hooks/useUploadLargeFileToS3";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import { StudentTestPageContext } from "../../../context/StudentTestPageContext";
import StudentTestPageHooks from "../../../Hooks/StudentTestPage/StudentTestPageHooks";
import StudentTestBodyPageHooks from "../../../Hooks/StudentTestPage/StudentTestBodyPageHooks";
import StudentTestContainerHooks from "../../../Hooks/StudentTestPage/StudentTestContainerHooks";
import CodingHeaderLearnerSideHooks from "../../../Hooks/CodingPage/CodingHeaderLearnerSideHooks";
import StudentTestPageQuestionUpdateHooks from "../../../Hooks/StudentTestPage/StudentTestPageQuestionUpdateHooks";
import StudentTestBodyPageSingleViewHooks from "../../../Hooks/StudentTestPage/StudentTestBodyPageSingleViewHooks";
import Verifypic from "../../CommonComponents/Verifypic";
import WarningSvg from "../../Notification-Loading/Notify/Svg/WarningSvg";

export default function StudentTestQuestionPage() {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const axios = useAxiosPrivate();
  const useCommonFunctionHks = useCommonFunctionHooks();
  const StudentTestBodyPageHks = StudentTestBodyPageHooks();
  const StudentTestContainerHks = StudentTestContainerHooks();
  const StudentTestBodyPageSingleViewHks = StudentTestBodyPageSingleViewHooks();

  const { scrollToQuestion } = StudentTestPageHooks();
  const { submitAnswers } = CodingHeaderLearnerSideHooks();
  const { uploadLargeFileToS3 } = useUploadLargeFileToS3();
  const { questionUpdate } = StudentTestPageQuestionUpdateHooks();

  /* for audio video record */

  let { startRecording, stopRecording, error, previewStream } =
    useReactMediaRecorder({
      video: true,
      onStop: (blobUrl, blob) => handleStopRecord(blobUrl, blob),
    });

  const isNextClicked = useRef(false);

  const [isBlurred, setIsBlurred] = useState(false);

  const toggleBlur = () => {
    setIsBlurred(!isBlurred);
  };

  const handleStopRecord = async (blobUrl, blob) => {
    let currentObj = Alert.videoQn.current.filter((data) => data.selected)[0];

    let anstext = `VideoRecord/${Alert.comingObj.testid}/${getDataFromStorage(
      "learnerid"
    )}/${currentObj.id}.mp4`;

    let temp = JSON.parse(JSON.stringify(Alert.videoQn.current));

    let currentIndex = temp.findIndex((det) => det.selected === true);
    let temp2;

    if (currentIndex !== -1) {
      temp[currentIndex].selected = false;
      temp[currentIndex].answer = anstext;

      // Make questionlit answerd true

      temp2 = JSON.parse(JSON.stringify(Alert.questionListObj.current));

      for (let det1 in temp2) {
        for (let det2 in temp2[det1].qnsObj) {
          if (temp2[det1].qnsObj[det2].qId === currentObj.id) {
            temp2[det1].qnsObj[det2].isAnswered = true;
            temp2[det1].qnsObj[det2].transcript =
              StudentTestPageCxt.transcript.current;
          }
        }
      }
    }

    if (Alert.exitVdoSubmit.current) {
      Alert.videoQn.current = temp;
      Alert.questionListObj.current = temp2;
    }

    if (!Alert.exitVdoSubmit.current) {
      if (isNextClicked.current) {
        let nextIndex = (currentIndex + 1) % temp.length;

        if (nextIndex === 0 && currentIndex !== -1) {
          let unselectedIndex = temp.findIndex(
            (det) => det.selected !== true && det.selected !== false
          );

          if (unselectedIndex !== -1) {
            temp[unselectedIndex].selected = true;
            StudentTestPageCxt.videoQnNo.current =
              parseInt(unselectedIndex) + 1;

            for (let det1 in temp2) {
              for (let det2 in temp2[det1].qnsObj) {
                if (temp2[det1].qnsObj[det2].qId === temp[unselectedIndex].id) {
                  StudentTestPageCxt.setVdoQnTimelimit(
                    temp2[det1].qnsObj[det2].qnduration * 60
                  );
                }
              }
            }

            let count = temp.filter(
              (dat) => dat.selected === true || dat.selected === false
            ).length;

            if (count === temp.length) {
              let count = 0;
              let trueCount = 0;

              for (let det1 in temp2) {
                for (let det2 in temp2[det1].qnsObj) {
                  count++;

                  if (temp2[det1].qnsObj[det2].isAnswered) {
                    trueCount++;
                  }
                }
              }

              let isShowSubmitBtn = count - trueCount;

              if (isShowSubmitBtn === 1) {
                StudentTestPageCxt.showSubmitBtn.current = true;
              } else {
                StudentTestPageCxt.setDisableNxtBtn(true);
              }
            }
          } else {
            let count = 0;
            let trueCount = 0;

            for (let det1 in temp2) {
              for (let det2 in temp2[det1].qnsObj) {
                count++;

                if (temp2[det1].qnsObj[det2].isAnswered) {
                  trueCount++;
                }
              }
            }

            let isShowSubmitBtn = count - trueCount;

            if (isShowSubmitBtn === 1) {
              StudentTestPageCxt.showSubmitBtn.current = true;
            } else {
              StudentTestPageCxt.setDisableNxtBtn(true);
            }
          }
        } else {
          if (temp[nextIndex].selected === undefined) {
            temp[nextIndex].selected = true;
            StudentTestPageCxt.videoQnNo.current = parseInt(nextIndex) + 1;

            for (let det1 in temp2) {
              for (let det2 in temp2[det1].qnsObj) {
                if (temp2[det1].qnsObj[det2].qId === temp[nextIndex].id) {
                  StudentTestPageCxt.setVdoQnTimelimit(
                    temp2[det1].qnsObj[det2].qnduration * 60
                  );
                }
              }
            }

            let count = temp.filter(
              (dat) => dat.selected === true || dat.selected === false
            ).length;

            if (count === temp.length) {
              let count = 0;
              let trueCount = 0;

              for (let det1 in temp2) {
                for (let det2 in temp2[det1].qnsObj) {
                  count++;

                  if (temp2[det1].qnsObj[det2].isAnswered) {
                    trueCount++;
                  }
                }
              }

              let isShowSubmitBtn = count - trueCount;

              if (isShowSubmitBtn === 1) {
                StudentTestPageCxt.showSubmitBtn.current = true;
              } else {
                StudentTestPageCxt.setDisableNxtBtn(true);
              }
            }
          } else {
            if (temp[nextIndex].selected === true) {
              temp[nextIndex].selected = false;

              let count = 0;
              let trueCount = 0;

              for (let det1 in temp2) {
                for (let det2 in temp2[det1].qnsObj) {
                  count++;

                  if (temp2[det1].qnsObj[det2].isAnswered) {
                    trueCount++;
                  }
                }
              }

              let isShowSubmitBtn = count - trueCount;

              if (isShowSubmitBtn === 1) {
                StudentTestPageCxt.showSubmitBtn.current = true;
              } else {
                StudentTestPageCxt.setDisableNxtBtn(true);
              }
            } else {
              let missingIndex = temp.findIndex(
                (det) => det.selected !== true && det.selected !== false
              );

              if (missingIndex !== -1) {
                temp[missingIndex].selected = true;
                StudentTestPageCxt.videoQnNo.current =
                  parseInt(missingIndex) + 1;

                for (let det1 in temp2) {
                  for (let det2 in temp2[det1].qnsObj) {
                    if (
                      temp2[det1].qnsObj[det2].qId === temp[missingIndex].id
                    ) {
                      StudentTestPageCxt.setVdoQnTimelimit(
                        temp2[det1].qnsObj[det2].qnduration * 60
                      );
                    }
                  }
                }

                let isComplete = temp.filter(
                  (dat) => dat.selected === true || dat.selected === false
                ).length;

                if (isComplete === temp.length) {
                  let count = 0;
                  let trueCount = 0;

                  for (let det1 in temp2) {
                    for (let det2 in temp2[det1].qnsObj) {
                      count++;

                      if (temp2[det1].qnsObj[det2].isAnswered) {
                        trueCount++;
                      }
                    }
                  }

                  let isShowSubmitBtn = count - trueCount;

                  if (isShowSubmitBtn === 1) {
                    StudentTestPageCxt.showSubmitBtn.current = true;
                  } else {
                    StudentTestPageCxt.setDisableNxtBtn(true);
                  }
                }
              } else {
                let count = 0;
                let trueCount = 0;

                for (let det1 in temp2) {
                  for (let det2 in temp2[det1].qnsObj) {
                    count++;

                    if (temp2[det1].qnsObj[det2].isAnswered) {
                      trueCount++;
                    }
                  }
                }

                let isShowSubmitBtn = count - trueCount;

                if (isShowSubmitBtn === 1) {
                  StudentTestPageCxt.showSubmitBtn.current = true;
                } else {
                  StudentTestPageCxt.setDisableNxtBtn(true);
                }
              }
            }
          }
        }

        Alert.videoQn.current = temp;
        Alert.questionListObj.current = temp2;
        Alert.setQueObj(temp);

        if (isNextClicked.current) {
          Alert.qnTrack.current += 1;
          StudentTestPageCxt.setShowTimer(true);
          StudentTestPageCxt.setshowtransition(true);
          startRecording();
        } else {
          if (StudentTestPageCxt.disableNxtBtn) {
            StudentTestPageCxt.setDisableNxtBtn(false);
          }

          StudentTestPageCxt.setVideoQuestionAttempt(true);
          Alert.setHideSubmitVideoQn(true);
        }
      } else {
        StudentTestPageCxt.videoQnNo.current = 0;
        Alert.videoQn.current = temp;
        Alert.questionListObj.current = temp2;
        Alert.setQueObj(temp);

        StudentTestPageCxt.setVideoQuestionAttempt(true);
        Alert.setHideSubmitVideoQn(true);

        if (StudentTestPageCxt.disableNxtBtn) {
          StudentTestPageCxt.setDisableNxtBtn(false);
        }

        StudentTestPageCxt.setVideoQuestionAttempt(true);
        Alert.setHideSubmitVideoQn(true);
      }
    }

    // await uploadLargeFileToS3(
    //   blob,
    //   `VideoRecord/${Alert.comingObj.testid}/${getDataFromStorage(
    //     "learnerid"
    //   )}/${currentObj.id}.mp4`,
    //   blob.type
    // );
    uploadLargeFileToS3(blob, anstext, blob.type)
      .then((res) => {
        if (res === "Success") {
          Alert.setVideoSaved((prev) => prev + 1);
          saveCurrentAnswer(currentObj.id, anstext);
        } else {
          temp = JSON.parse(JSON.stringify(Alert.videoQn.current));

          temp.forEach((data) => {
            if (data.id == currentObj.id) {
              delete data.selected;
              delete data.answer;
            }
          });

          temp2 = JSON.parse(JSON.stringify(Alert.questionListObj.current));

          for (let det1 in temp2) {
            for (let det2 in temp2[det1].qnsObj) {
              if (temp2[det1].qnsObj[det2].qId === currentObj.id) {
                temp2[det1].qnsObj[det2].isAnswered = false;
              }
            }
          }

          Alert.videoQn.current = temp;
          Alert.questionListObj.current = temp2;
          Alert.setUpdatingStates(true);
        }
      })
      .catch((error) => {
        // Make questionlit answerd true
        temp = JSON.parse(JSON.stringify(Alert.videoQn.current));

        temp.forEach((data) => {
          if (data.id == currentObj.id) {
            delete data.selected;
            delete data.answer;
          }
        });

        temp2 = JSON.parse(JSON.stringify(Alert.questionListObj.current));

        for (let det1 in temp2) {
          for (let det2 in temp2[det1].qnsObj) {
            if (temp2[det1].qnsObj[det2].qId === currentObj.id) {
              temp2[det1].qnsObj[det2].isAnswered = false;
            }
          }
        }

        Alert.videoQn.current = temp;
        Alert.questionListObj.current = temp2;
        Alert.setUpdatingStates(true);
      });
  };

  const saveCurrentAnswer = (qid, anslink) => {
    axios
      .post(
        "node/learner/question/saveans",
        JSON.stringify({
          testid: Alert.testData.testid,
          mockuse: Alert.testData.mockuse,
          timeleft: Alert.duration.current,
          libtype: Alert.testData.testtype,
          testmode: Alert.testData.testmode,
          lpconfigid: Alert.testData.lpconfigid,
          answers: [{ qnid: qid, answer: anslink }],
          learnerid: getDataFromStorage("learnerid"),
        }),
        useCommonFunctionHks.getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          console.log(
            " test submit Alert.exitVdoSubmit.current ",
            Alert.exitVdoSubmit.current
          );
          console.log(
            " test submit StudentTestPageCxt.showSubmitBtn.current ",
            StudentTestPageCxt.showSubmitBtn.current
          );
          console.log(
            " test submit Alert.exitVdoSubmit.current ",
            Alert.exitVdoSubmit.current
          );
          console.log(
            " test submit Alert.timeVdoSubmit.current ",
            Alert.timeVdoSubmit.current
          );

          if (
            (!isNextClicked.current &&
              StudentTestPageCxt.showSubmitBtn.current) ||
            Alert.exitVdoSubmit.current
          ) {
            Alert.exitVdoSubmit.current
              ? Alert.timeVdoSubmit.current
                ? submitAnswers(false, true)
                : submitAnswers(true, false)
              : submitAnswers();
          }
        }
      })
      .finally(() => Alert.setIsLoaded(false));
  };

  /* question onclick */

  const handleClick = (section, question, testdata) => {
    let tempQueInd = Alert.queInd;
    let tempSecInd = Alert.secInd;

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === -1
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "not";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "not";
    }

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === 0
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "completed";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "completed";
    }

    let sections;

    sections = Alert.comingObj?.sections?.filter(
      (sect, sindex) => sindex === section
    );

    StudentTestPageCxt.setFSections(sections);
    StudentTestPageCxt.setSelSec(section);
    Alert.setQueObj(
      Alert.comingObj.sections[Alert.secInd].questions[Alert.queInd]
    );

    /* set current  */

    Alert.updateComingObj(Alert.comingObj);
    questionUpdate(section, question, testdata);
    Alert.setSecInd(section);
    StudentTestPageCxt.setSecIndex(section);
  };

  const multiTraverse = (divid, secind, queind) => {
    let tempSecInd = secind;

    StudentTestPageCxt.setSelSec(tempSecInd);
    Alert.setSecInd(secind);
    StudentTestPageCxt.setSecIndex(secind);

    if (Alert.comingObj.sections?.length > 1) {
      if (Alert.comingObj.sections?.length === tempSecInd + 1) {
        StudentTestPageCxt.setNextSec(false);
        StudentTestPageCxt.setPrevSec(true);
      } else if (tempSecInd + 1 === 1) {
        StudentTestPageCxt.setNextSec(true);
        StudentTestPageCxt.setPrevSec(false);
      } else {
        StudentTestPageCxt.setNextSec(true);
        StudentTestPageCxt.setPrevSec(true);
      }
    }

    if (Alert.comingObj.sections?.length === 1) {
      StudentTestPageCxt.setNextSec(false);
      StudentTestPageCxt.setPrevSec(false);
    }

    setTimeout(() => {
      const element = document.getElementById(divid);

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }, 500);

    questionUpdate(secind, queind, Alert.comingObj);
    StudentTestPageCxt.setSecIndex(secind);
  };

  // submitting scores

  const submitScores = () => {
    let testTypeId = Alert.comingObj.sections[0].qnconfig[0].libtypeid;

    if (
      testTypeId === constants.libTypeIds.SAQ ||
      testTypeId === constants.libTypeIds.LAQ
    ) {
      testTypeId = constants.libTypeIds.DESCRIPTIVE;
    }

    if (
      testTypeId === constants.libTypeIds.MCQ_MULTI ||
      testTypeId === constants.libTypeIds.MCQ_SINGLE
    ) {
      testTypeId = constants.libTypeIds.MCQ;
    }

    Alert.setAction("submitscores");
    Alert.setIsLoaded(true);
    clearInterval(Alert.interval.current);
    useCommonFunctionHks.handleClose();

    let studentScores = [];
    let tmpCurSco = 0;

    let isEvalcomplete = true;

    for (let i = 0; i < Alert.comingObj.sections?.length; i++) {
      for (let j = 0; j < Alert.comingObj.sections[i].questions.length; j++) {
        const qnId = Alert.comingObj.sections[i].questions[j].id;

        if (
          Alert.comingObj.sections[i].questions[j].answer === "" ||
          Alert.comingObj.sections[i].questions[j].answer == null
        ) {
          if (Alert.descScores.hasOwnProperty(qnId)) {
            tmpCurSco += Alert.descScores[qnId];
            studentScores.push({
              questionid: qnId,
              newscore: Alert.descScores[qnId],
            });
          } else {
            tmpCurSco += 0;
            studentScores.push({
              newscore: 0,
              questionid: qnId,
            });
          }
        } else {
          if (Alert.descScores.hasOwnProperty(qnId)) {
            tmpCurSco += Alert.descScores[qnId];
            studentScores.push({
              questionid: qnId,
              newscore: Alert.descScores[qnId],
            });
          } else {
            if (
              Alert.comingObj.sections[i].questions[j].answer !== "" ||
              Alert.comingObj.sections[i].questions[j].answer !== null
            ) {
              tmpCurSco += Alert.comingObj.sections[i].questions[j].actualscore;
              studentScores.push({
                questionid: qnId,
                newscore:
                  Alert.comingObj.sections[i].questions[j].actualscore !== null
                    ? Alert.comingObj.sections[i].questions[j].actualscore
                    : 0,
              });
            } else {
              isEvalcomplete = false;
            }
          }
        }
      }
    }

    StudentTestPageCxt.setEvalComp(isEvalcomplete);
    StudentTestPageCxt.setCurrentScore(tmpCurSco);
    Alert.setTotalLearnerScore(tmpCurSco);

    axios
      .post(
        "node/admin/test/score",
        JSON.stringify({
          libtypeid: testTypeId,
          complete: isEvalcomplete,
          scoredata: studentScores,
          timeleft: Alert.duration.current,
          testid: Alert.mcqAndDesTestAndLpId.testid,
          learnerid: Alert.mcqAndDesTestAndLpId.learnerid,
          lpconfigid: Alert.mcqAndDesTestAndLpId.lpconfigid,
        }),
        useCommonFunctionHks.getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.message,
          });
        } else {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.message,
          });
        }
      })
      .catch((error) => {
        useCommonFunctionHks.getCatchBlockDetails(error);
      })
      .finally(() => Alert.setIsLoaded(false));
  };

  const handleConformationPopUp = () => {
    Alert.setShowNotify({
      show: true,
      title: "Warning !",
      primaryButton: "Confirm",
      secondaryButton: "Cancel",
      msg: "Do you really want to save",
      primaryButtonFunction: submitScores,
    });
  };

  useEffect(() => {
    if (
      getDataFromStorage("role") === constants.Roles.learner &&
      Alert.mcqAndDesTestAndLpId.status !==
        constants.PROGRESS_STATUS.EVALUATED &&
      Alert.mcqAndDesTestAndLpId.status !==
        constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
      Alert.mcqAndDesTestAndLpId.status !==
        constants.PROGRESS_STATUS.PARTIAL_EVALUATED &&
      StudentTestPageCxt.startVideo &&
      Alert.comingObj.videoqn === 1
    ) {
      startRecording();
      StudentTestPageCxt.setStartVideo(false);
    }
  }, [StudentTestPageCxt.startVideo]);

  const handleNextClick = () => {
    StudentTestBodyPageHks.stopListening();

    // StudentTestBodyPageSingleViewHks.playQns(
    //   "Thank you for your response! We are moving on to the next question."
    // );
    // setTimeout(() => {
      isNextClicked.current = true;
      stopRecording();
    // }, 5000);
  };

  const handleBackToListClick = () => {
    StudentTestBodyPageHks.stopListening();

    // StudentTestBodyPageSingleViewHks.playQns(
    //   "Thank you for your response! We are now navigating to the summary page."
    // );
    // setTimeout(() => {
      isNextClicked.current = false;
      stopRecording();
    // }, 5000);
  };

  useEffect(() => {
    if (Alert.singleVdoSubmit && Alert.comingObj.videoqn === 1) {
      StudentTestBodyPageHks.stopListening();
      // StudentTestBodyPageSingleViewHks.playQns(
      //   `You were unable to answer this question within the given time. We will submit your response for this question. If there are more 
      //   questions, you can proceed to the next one.`
      // );

      // setTimeout(() => {
        if(Alert.singleVdoSubmit && !Alert.exitVdoSubmit.current){
          Alert.setSingleVdoSubmit(false);
          isNextClicked.current = false;
          stopRecording();
        } else if (Alert.exitVdoSubmit.current && Alert.singleVdoSubmit) {
          stopRecording();
        }
      // }, 10000);
    }

    // if (Alert.exitVdoSubmit.current) {
    //   console.log("calling the useeffect student test quesi");

    //   stopRecording();
    // }
  }, [Alert.singleVdoSubmit, Alert.exitVdoSubmit.current]);


  // useEffect(()=>{

  // },[Alert.verifyMsg])
  

  return (
    <div className="common-testpage-questions-div">
      {(!Alert.isAdmin && Alert.comingObj.videoqn !== 1) ||
      Alert.mcqAndDesTestAndLpId.status ===
        constants.PROGRESS_STATUS.EVALUATED ||
      Alert.mcqAndDesTestAndLpId.status ===
        constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
      Alert.mcqAndDesTestAndLpId.status ===
        constants.PROGRESS_STATUS.PARTIAL_EVALUATED ||
      (Alert.isAdmin && Alert.comingObj.videoqn === 1) ||
      (Alert.isAdmin && Alert.comingObj.videoqn !== 1) ? (
        Alert.mcqAndDesTestAndLpId.status ===
          constants.PROGRESS_STATUS.EVALUATED ||
        Alert.mcqAndDesTestAndLpId.status ===
          constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
        Alert.mcqAndDesTestAndLpId.status ===
          constants.PROGRESS_STATUS.PARTIAL_EVALUATED ||
        (Alert.isAdmin &&
          Alert.mcqAndDesTestAndLpId.status ===
            constants.PROGRESS_STATUS.IN_PROGRESS) ||
        (Alert.isAdmin &&
          Alert.mcqAndDesTestAndLpId.status ===
            constants.PROGRESS_STATUS.QN_CONTINUE) ||
        (Alert.isAdmin &&
          Alert.mcqAndDesTestAndLpId.status ===
            constants.PROGRESS_STATUS.REATTEMPT) ? (
          <>
            <div>
              <div className="common-testpage-submitted-questions-details">
                <div className="submitted-questions-details-table-firstdiv">
                  Question Type
                </div>
                <div className="submitted-questions-details-table-seconddiv">
                  No. of Questions
                </div>
              </div>

              <div className="completed-question-details-div">
                <div className="completed-question-checkbox-div">
                  {/* <span className="completed-question-allfilter-checkbox-span"> */}
                  <input
                    name="tq"
                    type="checkbox"
                    key="filterchkqa"
                    value={"attempted"}
                    onChange={StudentTestContainerHks.setFilters}
                    className="completed-question-allfilter-checkbox"
                    checked={
                      StudentTestPageCxt.viewFilters !== undefined &&
                      StudentTestPageCxt.viewFilters.hasOwnProperty("tq")
                        ? StudentTestPageCxt.viewFilters["tq"]
                        : false
                    }
                  />
                  {/* </span> */}
                  <span className="completed-question-allfilter-checkbox-label">
                    All
                  </span>
                </div>
                <div className="completed-question-count-div">
                  {Alert.testStats.tq}
                </div>
              </div>
              <div className="completed-question-details-div">
                <div className="completed-question-checkbox-div">
                  {/* <span className="filterCheckboxDiv"> */}
                  <input
                    name="qa"
                    type="checkbox"
                    key="filterchkqa"
                    value={"attempted"}
                    disabled={Alert.testStats.qa === 0}
                    onChange={StudentTestContainerHks.setFilters}
                    className="completed-question-allfilter-checkbox"
                    checked={
                      StudentTestPageCxt.viewFilters !== undefined &&
                      StudentTestPageCxt.viewFilters.hasOwnProperty("qa")
                        ? StudentTestPageCxt.viewFilters["qa"]
                        : false
                    }
                  />
                  {/* </span> */}
                  <span className="completed-question-allfilter-checkbox-label">
                    Attempted
                  </span>
                </div>
                <div className="completed-question-count-div">
                  {Alert.testStats.qa}/{Alert.testStats.tq}
                </div>
              </div>
              <div className="completed-question-details-div">
                <div className="completed-question-checkbox-div">
                  {/* <span className="filterCheckboxDiv"> */}
                  <input
                    name="na"
                    type="checkbox"
                    key="filterchkqa"
                    value={"attempted"}
                    disabled={Alert.testStats.na === 0}
                    onChange={StudentTestContainerHks.setFilters}
                    className="completed-question-allfilter-checkbox"
                    checked={
                      StudentTestPageCxt.viewFilters !== undefined &&
                      StudentTestPageCxt.viewFilters.hasOwnProperty("na")
                        ? StudentTestPageCxt.viewFilters["na"]
                        : false
                    }
                  />
                  {/* </span> */}
                  <span className="completed-question-allfilter-checkbox-label">
                    Not Attempted
                  </span>
                </div>
                <div className="completed-question-count-div">
                  {Alert.testStats.na}/{Alert.testStats.tq}
                </div>
              </div>
              {(Alert.isAdmin ||
                StudentTestPageCxt.isTrainer ||
                (StudentTestPageCxt.isLearner &&
                  StudentTestPageCxt.viewTest ===
                    constants.DISPLAY_TEST_RESULT.ALL)) &&
                (Alert.mcqAndDesTestAndLpId.libtypeid ===
                  constants.libTypeIds.MCQ ||
                  Alert.mcqAndDesTestAndLpId.libtypeid ===
                    constants.libTypeIds.DESCRIPTIVE) && (
                  <>
                    <div className="completed-question-details-div">
                      <div className="completed-question-checkbox-div">
                        {/* <span className="filterCheckboxDiv"> */}
                        <input
                          name="ca"
                          type="checkbox"
                          key="filterchkqa"
                          value={"attempted"}
                          onChange={StudentTestContainerHks.setFilters}
                          className="completed-question-allfilter-checkbox"
                          checked={
                            StudentTestPageCxt.viewFilters.hasOwnProperty("ca")
                              ? StudentTestPageCxt.viewFilters["ca"]
                              : false
                          }
                          disabled={
                            Alert.testStats.ca === 0 ||
                            Alert.mcqAndDesTestAndLpId.libtypeid ===
                              constants.libTypeIds.DESCRIPTIVE
                          }
                        />
                        {/* </span> */}
                        <span className="completed-question-allfilter-checkbox-label">
                          Correct
                        </span>
                      </div>
                      <div className="completed-question-count-div">
                        {Alert.testStats.ca}/{Alert.testStats.tq}
                      </div>
                    </div>

                    <div className="completed-question-details-div">
                      <div className="completed-question-checkbox-div">
                        {/* <span className="filterCheckboxDiv"> */}
                        <input
                          name="wa"
                          type="checkbox"
                          key="filterchkqa"
                          value={"attempted"}
                          onChange={StudentTestContainerHks.setFilters}
                          className="completed-question-allfilter-checkbox"
                          checked={
                            StudentTestPageCxt.viewFilters.hasOwnProperty("wa")
                              ? StudentTestPageCxt.viewFilters["wa"]
                              : false
                          }
                          disabled={
                            Alert.testStats.wa === 0 ||
                            Alert.mcqAndDesTestAndLpId.libtypeid ===
                              constants.libTypeIds.DESCRIPTIVE
                          }
                        />
                        {/* </span> */}
                        <span className="completed-question-allfilter-checkbox-label">
                          Wrong
                        </span>
                      </div>
                      <div className="completed-question-count-div">
                        {Alert.testStats.wa}/{Alert.testStats.tq}
                      </div>
                    </div>
                  </>
                )}

              {Alert.isAdmin &&
                (Alert.backupTestData.sections[0].qnconfig[0].libtypeid ===
                  constants.libTypeIds.DESCRIPTIVE ||
                  Alert.backupTestData.sections[0].qnconfig[0].libtypeid ===
                    constants.libTypeIds.SAQ ||
                  Alert.backupTestData.sections[0].qnconfig[0].libtypeid ===
                    constants.libTypeIds.LAQ) &&
                Alert.testMode === constants.MODE.TEST_MODE && (
                  <>
                    <div className="score-save-btn">
                      <Button
                        size={"sm"}
                        onClick={handleConformationPopUp}
                        disable={
                          StudentTestPageCxt.hasScoreErrors ||
                          Alert.descScores.length > 0
                        }
                        hierarchy={{
                          type: "primary",
                          buttonText: "Save",
                        }}
                      />
                    </div>
                  </>
                )}
            </div>
          </>
        ) : (
          <>
            <div className="common-testpage-questions-header-div">
              <Accordion className="common-testpage-questions-header-accordian">
                <AccordionSummary className="common-testpage-questions-header-accordian-summary">
                  <div className="common-testpage-questions-header-title">
                    Question List
                  </div>
                  <div className="common-testpage-questions-header-legend">
                    <IconButton>
                      <InfoSvg />
                    </IconButton>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="common-testpage-questions-header-accordian-details">
                  <div>
                    <div className="common-testpage-questions-header-accordian-icon1"></div>
                    <div className="common-testpage-questions-header-accordian-detail">
                      Question not viewed yet
                    </div>
                  </div>
                  <div>
                    <div className="common-testpage-questions-header-accordian-icon2"></div>
                    <div className="common-testpage-questions-header-accordian-detail">
                      Current question
                    </div>
                  </div>
                  <div>
                    <div className="common-testpage-questions-header-accordian-icon3"></div>
                    <div className="common-testpage-questions-header-accordian-detail">
                      Question viewed but not answered
                    </div>
                  </div>
                  <div>
                    <div className="common-testpage-questions-header-accordian-icon4"></div>
                    <div className="common-testpage-questions-header-accordian-detail">
                      Question marked for review
                    </div>
                  </div>
                  <div>
                    <div className="common-testpage-questions-header-accordian-icon5"></div>
                    <div className="common-testpage-questions-header-accordian-detail">
                      Question completed
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="common-testpage-questions-list-div">
              {Alert.comingObj.sections.map((sect, sectindex) => (
                <>
                  <div className="common-testpage-section-name-div">
                    <span className="common-testpage-section-title">
                      Section :
                    </span>
                    <span className="common-testpage-section-name">
                      {sect.sectionname}
                    </span>
                  </div>
                  <div className="common-testpage-questions-lists">
                    {sect.questions.map((question, index) => (
                      <button
                        className="common-testpage-questions-lists-btn"
                        key={question.id}
                        onClick={scrollToQuestion(index)}
                        style={{ textDecoration: "none" }}
                      >
                        <StudentTestQuestionBox
                          index={index}
                          value={index + 1}
                          key={question.id}
                          section={sectindex}
                          secInd={Alert.secInd}
                          queInd={Alert.queInd}
                          review={question.review}
                          handleClick={handleClick}
                          testData={Alert.comingObj}
                          visited={question.visited}
                          current={question.current}
                          multiTraverse={multiTraverse}
                          previsited={question.previsited}
                          quedivid={"que" + sectindex + index}
                          questionContainerSingle={
                            Alert.questionContainerSingle
                          }
                        />
                      </button>
                    ))}
                  </div>
                </>
              ))}
            </div>
          </>
        )
      ) : (
        <>
          <div className="video-record-div">
            {error === "permission_denied" && (
              <p>Please grant permission to use your camera and microphone.</p>
            )}
            {!error && (
              <>
                <div>
                  <StudentTestVideoPreview
                    isBlurred={isBlurred}
                    stream={previewStream}
                  />
                </div>
              </>
            )}

            <IconButton onClick={toggleBlur} className="cam-blur-btn">
              {isBlurred ? <NoProctoringIcon /> : <ProctorEyeSvg />}
            </IconButton>

            {
             (Alert.verifyMsg.hasOwnProperty("resultCode") && Alert.verifyMsg.resultCode !== constants.RESULT_STATUS.SUCCESS && Alert.verifyMsg.resultCode !== constants.RESULT_STATUS.TIMEOUT)
              && (<div className="interviewMultiFaceDeducted">
              <div className="interviewMultiFaceDeductedHeader">
                <WarningSvg component="interview" />
                {Alert.verifyMsg.Multi_People_Found ? "Multiple Individuals Detected" : "Face Not Found"}
              </div>
              <div className="interviewMultiFaceDeductedBody">{Alert.verifyMsg.Multi_People_Found ? "Multiple individuals detected. Please ensure you are alone to continue the interview." : "Your face is not visible in the camera. Please ensure you are within the camera frame to continue the interview."}</div>
            </div>)}

          </div>

          <div className="video-record-buttons">
            <Button
              size={"sm"}
              onClick={handleBackToListClick}
              disable={StudentTestPageCxt.showtransition}
              hierarchy={{
                type: "secondaryGrey",
                buttonText: "Back to summary",
              }}
            />
            {!StudentTestPageCxt.showSubmitBtn.current && (
              <Button
                size={"sm"}
                onClick={handleNextClick}
                disable={
                  StudentTestPageCxt.showtransition ||
                  StudentTestPageCxt.disableNxtBtn
                }
                hierarchy={{
                  type: "primary",
                  buttonText: "Next",
                }}
              />
            )}

            {StudentTestPageCxt.showSubmitBtn.current && (
              <Button
                size={"sm"}
                onClick={handleBackToListClick}
                disable={StudentTestPageCxt.showtransition}
                hierarchy={{
                  type: "primary",
                  buttonText: "Submit",
                }}
              />
            )}
          </div>
          {/* </>
          } */}
        </>
      )}

      {Alert.isTakeTest && Alert.comingObj.videoqn === 1 && Alert.mcqAndDesTestAndLpId.status !==
        constants.PROGRESS_STATUS.EVALUATED && (
        <Verifypic
          isTakeTest={Alert.isTakeTest}
        />
      )}

    </div>
  );
}
