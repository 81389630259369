import "./Tests.scss";
import { nanoid } from "nanoid";
import Select from "react-select";
import CreateTest from "./CreateTest";
import { Button } from "react-bootstrap";
import constants from "../../../constants";
import Scrollbars from "react-custom-scrollbars-2";
import DataTable from "react-data-table-component";
import AlertContext from "../../../context/AlertContext";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import Notify from "../../Notification-Loading/Notify/Notify";
import React, { useContext, useEffect, useState } from "react";
import Loading from "../../Notification-Loading/Loading/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TestList(Props) {
  let topicData;

  const {
    msg,
    show,
    title,
    lpData2,
    setMsg,
    setShow,
    setTitle,
    isLoaded,
    isUnAuth,
    navigate,
    fromMenu,
    setLpData2,
    setIsLoaded,
    setIsUnAuth,
    handleClose,
    setFromList,
    isFromBatch,
    isOldLpDetail,
    oldTestConfig,
    setIsFromBatch,
    setOldTestConfig,
    setIsOldLpDetail,
    handleUnAuthAlert,
    fromMenuToEditTest,
    setFromMenuToEditTest,
    handleNavigateToLpAfterAddTest,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const [isAddTest, setIsAddTest] = useState(
    oldTestConfig.isOldTestConfig ? true : false
  );

  const [lists, setLists] = useState([]);
  const [topics, setTopics] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isNewTest, setIsNewTest] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedTopicsForValue, setSelectedTopicsForValue] = useState([]);

  const topicOptions = [{ value: [], label: "All" }, ...topics];

  const columns = [
    {
      width: "35%",
      name: "Title",
      sortable: true,
      selector: (row) => row.title,
    },
    {
      width: "35%",
      name: "Topics",
      sortable: true,
      selector: (row) =>
        row.topics.map((topic, index) => {
          if (index === row.topics.length - 1) {
            return topic.topicname;
          } else {
            return topic.topicname + ",";
          }
        }),
    },
    {
      width: "10%",
      sortable: true,
      name: "No of Qn",
      selector: (row) => row.qncount,
    },
    {
      width: "12%",
      sortable: true,
      name: "Duration (mins)",
      selector: (row) => row.duration,
    },
  ];
  let contentColumn = [
    {
      width: "40%",
      name: "Title",
      sortable: true,
      selector: (row) => row.title,
    },
    {
      width: "20%",
      name: "Topics",
      sortable: true,
      selector: (row) =>
        row.topics.map((topic, index) => {
          if (index === row.topics.length - 1) {
            return topic.topicname;
          } else {
            return topic.topicname + ",";
          }
        }),
    },
    {
      width: "15%",
      sortable: true,
      name: "Createdby",
      selector: (row) => row.createdby,
    },
    {
      width: "20%",
      name: "Attachments",
      selector: (row) => (
        <ul>
          {row.topics.map((topic, index) =>
            topic.libattachments.map((attachment) => {
              let libAttachment =
                attachment.attachments !== null ? attachment.attachments : "";
              let index = libAttachment.lastIndexOf("/");

              return libAttachment !== "" ? (
                <li key={nanoid()}>
                  {attachment.type === constants.LINK_TYPE.EMBEDED_LINK ? (
                    <React.Fragment>
                      <a href={libAttachment} target="_blank" rel="noreferrer">
                        {libAttachment}
                      </a>
                      <br />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <a
                        href={process.env.REACT_APP_NODE_API + libAttachment}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {libAttachment.slice(index + 1)}
                      </a>
                      <br />
                    </React.Fragment>
                  )}
                </li>
              ) : (
                <React.Fragment>-</React.Fragment>
              );
            })
          )}
        </ul>
      ),
      width: "20%",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        color: "#9AA0A6",
        marginTop: "10px",
        fontWeight: "500",
        lineHeight: "15px",
        position: "sticky",
        fontFamily: "Inter",
        fontStyle: "normal",
        background: "#FFFFFF",
        textTransform: "uppercase",
      },
    },
    cells: {
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        fontFamily: "Inter",
        background: "#FFFFFF",
      },
    },
  };

  const filteredData = lists.filter((item) => {
    for (let i = 0; i < item.topics.length; i++) {
      const includesText = item.title
        .toLowerCase()
        .includes(searchText.toLowerCase());

      const includesTopic =
        selectedTopics.length === 0 ||
        selectedTopics[0].length === 0 ||
        selectedTopics.includes(item.topics[i].topicid);

      if (includesText && includesTopic === true) {
        return includesText && includesTopic;
      }
    }
  });

  useEffect(() => {
    if (!isAddTest) {
      let libTypeId = oldTestConfig.isOldTestConfig
        ? oldTestConfig.oldTestData.libTypeId
        : lpData2.libTypeId;

      setIsLoaded(true);
      axios
        .post("node/admin/test/get", JSON.stringify({ libtypeid: libTypeId }), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.resultCode !== 1000) {
            setIsLoaded(false);
            setShow(true);
            setTitle("Error !");
            setMsg(res.data.msg);
          } else {
            let tests;

            if (
              libTypeId !== constants.libTypeIds.CONTENT &&
              libTypeId !== constants.libTypeIds.ASSIGNMENT
            ) {
              tests = JSON.parse(res.data.data.tests);
            } else {
              tests = JSON.parse(res.data.data.data);
            }

            if (tests !== null) {
              setLists(tests);

              let topics = [];

              for (let i = 0; i < tests.length; i++) {
                for (let j = 0; j < tests[i].topics.length; j++) {
                  topics.push({
                    value: tests[i].topics[j].topicid,
                    label: tests[i].topics[j].topicname,
                  });
                  topics = [
                    ...new Map(topics.map((m) => [m.value, m])).values(),
                  ];
                }
              }

              setTopics(topics);

              if (fromMenu && fromMenuToEditTest) setIsAddTest(false);
            } else {
              setLists([]);
              setShow(true);
              setTitle("Warning !");
              setMsg("There is no data.");
            }

            setIsLoaded(false);
          }
        })
        .catch((error) => {
          setIsLoaded(false);
          setShow(true);
          setTitle("Error");
          if (error.message.includes("403")) {
            setIsUnAuth(true);
            setMsg("You have been logged-out due to inactivity. Login again.");
          } else setMsg(error.message);
        });
    }
  }, [
    oldTestConfig.isOldTestConfig,
    oldTestConfig.oldTestData.libTypeId,
    lpData2.libTypeId,
    fromMenuToEditTest,
    fromMenu,
    isAddTest,
  ]);

  const handleSearchTitle = (e) => {
    setSearchText(e.target.value);
  };

  const handleTopicSelect = (e) => {
    setSelectedTopics(Array.from(e, (e) => e.value));
    setSelectedTopicsForValue(e);
  };

  const handleSelectTopicAddToLp = (state) => {
    topicData = state.selectedRows.reverse();
  };

  const handleAddTopicToLp = () => {
    let newDetails = JSON.parse(JSON.stringify(lpData2)),
      uniqueId = 1,
      count = 0,
      found = 0;

    for (let i = 0; i < newDetails.modules.length; i++) {
      if (newDetails.modules[i].id === newDetails.moduleId) {
        for (let k = 0; k < topicData.length; k++) {
          for (let j = 0; j < newDetails.modules[i].moduleData.length; j++) {
            if (newDetails.modules[i].moduleData[j].hasOwnProperty("testId")) {
              if (
                newDetails.modules[i].moduleData[j].testId == null &&
                newDetails.modules[i].moduleData[j].libId == null
              ) {
                newDetails.modules[i].moduleData.splice(j, 1);
              } else {
                if (
                  newDetails.modules[i].moduleData[j].testId == topicData[k].id
                ) {
                  found = 1;
                  setShow(true);
                  setMsg("The Test is already added to path");
                  setTitle("Warning !");
                  break;
                }
              }
            } else {
              if (newDetails.modules[i].moduleData[j].id == topicData[k].id) {
                found = 1;
                setShow(true);
                setMsg("The Test is already added to path");
                setTitle("Warning !");
                break;
              }
            }
          }

          if (found === 0) {
            topicData[k].isEdited = false;
            topicData[k].libTypeId = lpData2.libTypeId;
            topicData[k].published = 0;
            topicData[k].status = "new";
            topicData[k].testStatus = "old";
            newDetails.modules[i].moduleData.push(topicData[k]);
          } else break;
        }
      }

      newDetails.modules[i].id = uniqueId;
      uniqueId++;

      if (found === 0) {
        for (let j = 0; j < newDetails.modules[i].moduleData.length; j++) {
          newDetails.modules[i].moduleData[j].moduleDataId = uniqueId;
          newDetails.modules[i].moduleData[j].key = nanoid();
          uniqueId++;
        }
      }

      count++;
    }

    newDetails.isAddTest = false;
    if (isFromBatch || isOldLpDetail) newDetails.status = "update";

    if (count === newDetails.modules.length && found === 0)
      handleNavigateToLpAfterAddTest(newDetails);
  };

  const handleCreateTestBtn = () => {
    if (lpData2.libTypeId === constants.libTypeIds.ASSIGNMENT) {
      setIsAddTest(false);
      Props.setselectque({ published: 0 });
      setFromList({ isFromList: true, libType: lpData2.libTypeId });
      Props.setTableShowStatus(lpData2.libTypeId);
      navigate("/library/assignment");
    } else if (lpData2.libTypeId === constants.libTypeIds.CONTENT) {
      setIsAddTest(false);

      Props.setselectque({ published: 0 });
      setFromList({ isFromList: true, libType: lpData2.libTypeId });
      Props.setTableShowStatus(lpData2.libTypeId);
      navigate("/library/contententry");
    } else {
      setIsNewTest(true);
      setIsAddTest(true);

      // Props.setIsOldLpDetail(true);
      // Props.setIsFromBatch(true);
    }
  };

  const handleBackBtn = () => {
    let newDetails = JSON.parse(JSON.stringify(lpData2));

    newDetails.isAddTest = false;
    handleNavigateToLpAfterAddTest(newDetails);
  };

  const handleEditedTestConfig = (lpData) =>
    Props.handleEditedTestConfig(lpData);

  const handleGetTestDetails = (data) => {
    if (fromMenu) {
      setIsLoaded(true);
      axios
        .get(
          process.env.REACT_APP_NODE_API + "node/admin/test/getbyid/" + data.id,
          {
            headers: {
              Authorization: localStorage.getItem("tokenKey"),
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          setIsLoaded(false);
          if (response.data.resultCode === 1000) {
            let data = JSON.parse(response.data.data)[0],
              count = 0,
              uniqueId = 1;

            data.isOld = true;
            data.status = "old";
            data.published = 0;
            data.testStatus = "old";
            data.libTypeId = data.libType;
            data.testDurationUnit = "min/s";
            data.testDurationNum = data.testDuration;
            data.testDuration = data.testDuration + "m";
            data.testType = { label: data.libName, value: data.libType };

            for (let i = 0; i < data.sections.length; i++) {
              count++;
              data.sections[i].id = uniqueId;
              data.sections[i].key = nanoid();
              data.sections[i].status = "old";
              data.sections[i].testStatus = "old";
              data.sections[i].type = "old";
              data.sections[i].isOld = true;
              uniqueId++;
              data.sections[i].secData = data.sections[i].secData.sort(
                (a, b) => a.orderno - b.orderno
              );
              for (let j = 0; j < data.sections[i].secData.length; j++) {
                data.sections[i].secData[j].id = uniqueId;
                data.sections[i].secData[j].key = nanoid();
                data.sections[i].secData[j].type = "old";
                data.sections[i].secData[j].isOld = true;
                data.sections[i].secData[j].topic = {
                  label: data.sections[i].secData[j].topicName,
                  value: data.sections[i].secData[j].topicId,
                };
                data.sections[i].secData[j].status = "old";
                data.sections[i].secData[j].desType = {
                  label: data.sections[i].secData[j].configLibName,
                  value: data.sections[i].secData[j].configLibType,
                };
                data.sections[i].secData[j].complexity = {
                  label: data.sections[i].secData[j].complexityName,
                  value: data.sections[i].secData[j].complexityId,
                };
                data.sections[i].secData[j].testStatus = "old";
                if (data.videoQn) {
                  data.sections[i].secData[j].durationNum =
                    data.sections[i].secData[j].duration /
                    (data.sections[i].secData[j].randomQnCount +
                      data.sections[i].secData[j].fixedQnCount);
                  data.sections[i].secData[j].durationUnit = "min/s";
                }

                if (data.libTypeId === constants.libTypeIds.CODING_CHALLENGE) {
                  let languages = [];

                  for (
                    let k = 0;
                    k < data.sections[i].secData[j].languages.length;
                    k++
                  ) {
                    languages.push({
                      value: data.sections[i].secData[j].languages[k].id,
                      label: data.sections[i].secData[j].languages[k].name,
                      key: nanoid(),
                    });
                  }

                  data.sections[i].secData[j].languages = languages;
                  data.sections[i].secData[j].durationNum =
                    data.sections[i].secData[j].duration /
                    (data.sections[i].secData[j].randomQnCount +
                      data.sections[i].secData[j].fixedQnCount);
                  data.sections[i].secData[j].durationUnit = "min/s";
                }

                uniqueId += 8;
              }

              if (count === data.sections.length) {
                setFromMenuToEditTest(false);
                setOldTestConfig({
                  isOldTestConfig: true,
                  oldTestData: data,
                  moduleId: 1,
                });
                setIsAddTest(true);
              }
            }
          } else {
            setShow(true);
            setTitle("Error");
            setMsg("Something went wrong. Please try again later");
          }
        })
        .catch((error) => {
          setIsLoaded(false);

          if (error.message.includes("403")) {
            setShow(true);
            setIsUnAuth(true);
            setTitle("Error !");
            setMsg("You have been logged-out due to inactivity. Login again..");
          }
        });
    }
  };

  return (
    <div className="adminTestContainer">
      {!isAddTest && (
        <>
          <div id="adminTestBtnContainer">
            <h2 className="adminTestTypeHead">
              {lpData2.libTypeId === constants.libTypeIds.MCQ
                ? "MCQ "
                : lpData2.libTypeId === constants.libTypeIds.DESCRIPTIVE
                ? "Descriptive "
                : lpData2.libTypeId === constants.libTypeIds.CONTENT
                ? "Content "
                : lpData2.libTypeId === constants.libTypeIds.ASSIGNMENT
                ? "Assignment "
                : "Coding Challenge "}
              List
            </h2>
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <FontAwesomeIcon
                icon={faSearch}
                className="adminTestSearchIcon"
              />
              <input
                type="text"
                id="search"
                name="search"
                value={searchText}
                placeholder="Search"
                onChange={handleSearchTitle}
                className="adminTestSearchBox"
              />
              <Select
                isMulti
                isSearchable={true}
                isClearable={false}
                options={topicOptions}
                placeholder="Choose topic"
                onChange={handleTopicSelect}
                value={selectedTopicsForValue}
                className="adminTestTopicSelect"
              />
              <Button
                variant="primary"
                id="adminAddTestBtn"
                onClick={handleCreateTestBtn}
              >
                {lpData2.libTypeId === constants.libTypeIds.ASSIGNMENT ||
                lpData2.libTypeId === constants.libTypeIds.CONTENT
                  ? "Create"
                  : "Create Test"}
              </Button>
            </div>
          </div>
          <Scrollbars
            autoHide
            key={nanoid()}
            style={{ width: "100%", height: "85.5vh", margin: "6px 0 0 0" }}
          >
            {/* <div className="testListDataTable"> */}
            <DataTable
              data={filteredData}
              selectableRows={!fromMenu}
              customStyles={customStyles}
              className="adminTestListTable"
              onSelectedRowsChange={handleSelectTopicAddToLp}
              onRowClicked={(data) => handleGetTestDetails(data)}
              columns={
                lpData2.libTypeId === constants.libTypeIds.CONTENT ||
                lpData2.libTypeId === constants.libTypeIds.ASSIGNMENT
                  ? contentColumn
                  : columns
              }
            />
            {/* </div> */}
          </Scrollbars>
          {!fromMenu ? (
            <div className="adminTestBtnSec">
              {/* <div className='adminTestSecondaryBtn'>
                            <ButtonMedium btnText2='Cancel' secondaryBtn={true} onHideSecondary={handleBackBtn} />
                        </div>
                        <div>
                            <ButtonMedium btnText='Add' primaryBtn={true} onHide={handleAddTopicToLp} />
                        </div> */}
              <Button
                onClick={handleBackBtn}
                style={{
                  color: "black",
                  background: "white",
                  marginRight: "10px",
                  border: "2px solid grey",
                }}
              >
                Back
              </Button>
              <Button
                onClick={handleAddTopicToLp}
                style={{ background: "#F55533", border: "none" }}
              >
                Add
              </Button>
            </div>
          ) : null}
        </>
      )}
      {isAddTest && (
        <CreateTest
          msg={msg}
          show={show}
          title={title}
          setMsg={setMsg}
          lpData={lpData2}
          setShow={setShow}
          fromMenu={fromMenu}
          setTitle={setTitle}
          isLoaded={isLoaded}
          isUnAuth={isUnAuth}
          setLists={setLists}
          isNewTest={isNewTest}
          setLpData={setLpData2}
          setIsLoaded={setIsLoaded}
          setIsUnAuth={setIsUnAuth}
          handleClose={handleClose}
          isFromBatch={isFromBatch}
          setIsAddTest={setIsAddTest}
          setIsNewTest={setIsNewTest}
          oldTestConfig={oldTestConfig}
          isOldLpDetail={isOldLpDetail}
          setIsFromBatch={setIsFromBatch}
          setIsOldLpDetail={setIsOldLpDetail}
          handleUnAuthAlert={handleUnAuthAlert}
          setFromMenuToEditTest={setFromMenuToEditTest}
          handleEditedTestConfig={handleEditedTestConfig}
          handleNavigateToLpAfterAddTest={handleNavigateToLpAfterAddTest}
        />
      )}
      {isLoaded && <Loading />}
      <Notify
        show={show}
        title={title}
        message={msg}
        onHide={isUnAuth ? handleUnAuthAlert : handleClose}
      />
    </div>
  );
}
