import Webcam from "react-webcam";
import Button from "../../Button";
import TestDetail from "./TestDetail";
import React, { useContext } from "react";
import constants from "../../../constants";
import WarningNotice from "./WarningNotice";
import { Editor } from "@tinymce/tinymce-react";
import ReviewSvgIcon from "../../../Svg/ReviewSvgIcon";
import InstructionPageBody from "./InstructionPageBody";
import AlertContext from "../../../context/AlertContext";
import RightArrowSvgIcon from "../../../Svg/RightArrowSvgIcon";
import InstructionPageHooks from "../../../Hooks/InstructionPageHooks";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";

export default function InstructionPage() {
  const Alert = useContext(AlertContext);

  const InstructionPageHks = InstructionPageHooks();
  const { handleWebCamError } = useCommonFunctionHooks();

  return (
    <div>
      {Alert.testData.libtype !== constants.libTypeIds.ASSIGNMENT && (
        <TestDetail />
      )}
      <div className="instructionPageMainContainer">
        <div className="instructionPage">
          {Alert.status !== constants.PROGRESS_STATUS.UNLOCKED ||
            (Alert.status === constants.PROGRESS_STATUS.IN_PROGRESS &&
              Alert.testData.libtype === constants.libTypeIds.ASSIGNMENT && (
                <WarningNotice />
              ))}
          {Alert.status !== constants.PROGRESS_STATUS.IN_PROGRESS && (
            <>
              <p className="instructionPageHeader">
                Please read the instructions carefully :
              </p>
              {Alert.testData.single ||
              Alert.testData.ainterview ||
              Alert.testData.videoqn === 1 ||
              Alert.testData.proctored === constants.PROCTORING_MODE.NONE ||
              Alert.testData.testtype ===
                constants.libTypeIds.CODING_CHALLENGE ? (
                <InstructionPageBody />
              ) : (
                <div className="instructionPageWebCamDiv">
                  <ul className="instructionPageBodyList">
                    {Alert.testData.proctored !==
                      constants.PROCTORING_MODE.NONE && (
                      <>
                        <li>
                          The countdown timer in the top right displays the
                          remaining time available.
                        </li>
                        <li>
                          When the countdown timer reaches zero, the test will
                          be auto-submitted.
                        </li>

                        <li className={"instructionPageBodyListInnerText"}>
                          {Alert.testData.testtype ===
                            constants.libTypeIds.DESCRIPTIVE &&
                          Alert.testData.videoqn !== 1
                            ? `If you switch tabs from this window, the assignment will be auto
                submitted. Every time you do this, your marks against this
                challenge will be reduced.`
                            : `The question selector on the right side of the screen will show the status of each question using these specific status:`}
                        </li>

                        <ul className="instructionPageBodyListUnordered">
                          <li className="instructionPageBodyUnorderedList">
                            <div className="instructionPageBodyUnorderedListNotViewLegend"></div>
                            <span className="instructionPageBodyUnorderedListLegendText">
                              You are yet to view this question.
                            </span>
                          </li>
                          <li className="instructionPageBodyUnorderedList">
                            <div className="instructionPageBodyUnorderedListViewLegend"></div>
                            <span className="instructionPageBodyUnorderedListLegendText">
                              You viewed the question, but did not answer.
                            </span>
                          </li>
                          <li className="instructionPageBodyUnorderedList">
                            <div className="instructionPageBodyUnorderedListReviewLegend"></div>
                            <span className="instructionPageBodyUnorderedListLegendText">
                              You have marked the question for review.
                            </span>
                          </li>
                          <li className="instructionPageBodyUnorderedList">
                            <div className="instructionPageBodyUnorderedListAnsweredLegend"></div>
                            <span className="instructionPageBodyUnorderedListLegendAnsweredText">
                              You have answered the question.
                            </span>
                          </li>
                        </ul>
                      </>
                    )}
                  </ul>
                  {Alert.testData.testmode === constants.MODE.TEST_MODE &&
                    Alert.testData.proctored ===
                      constants.PROCTORING_MODE.TAB_CAM && (
                      <Webcam
                        screenshotFormat="image/jpeg"
                        ref={InstructionPageHks.picRef}
                        className="instructionPageWebCam"
                        onUserMediaError={handleWebCamError}
                        videoConstraints={InstructionPageHks.videoConstraints}
                      />
                    )}
                </div>
              )}
              {!Alert.testData.single &&
                !Alert.testData.mockuse &&
                !Alert.testData.ainterview &&
                Alert.testData.videoqn !== 1 &&
                Alert.testData.testtype !==
                  constants.libTypeIds.CODING_CHALLENGE &&
                Alert.testData.proctored !== constants.PROCTORING_MODE.NONE && (
                  <ul className="instructionPageBodyList">
                    <li className="instructionPageBodyListInnerText">
                      You can bookmark a question to review later, by clicking
                      on the icon next to the question:
                    </li>
                    <ul className="instructionPageBodyListUnordered">
                      <li className="instructionPageBodyUnorderedList">
                        <ReviewSvgIcon />
                        <span className="instructionPageBodyUnorderedListLegendText">
                          Question not marked for review
                        </span>
                      </li>
                      <li className="instructionPageBodyUnorderedList">
                        <ReviewSvgIcon mark={"mark"} />
                        <span className="instructionPageBodyUnorderedListLegendText">
                          Question marked for review
                        </span>
                      </li>
                    </ul>
                  </ul>
                )}
              {Alert.testData.instruction !== null && (
                <div className="additionalInstructionDiv">
                  <p className="instructionPageSubHeader">
                    Additional instructions:
                  </p>
                  <Editor
                    disabled={true}
                    toolbar={false}
                    menubar={false}
                    branding={false}
                    statusbar={false}
                    value={Alert.testData.instruction}
                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                    init={{
                      resize: false,
                      readonly: true,
                      menubar: false,
                      statusbar: false,
                      license_key: "gpl",
                      selector: "textarea",
                      plugins: "autoresize",
                    }}
                  />
                </div>
              )}

              <div className="instructionPageFooter">
                <Button
                  size={"lg"}
                  disable={Alert.buttonDisable}
                  onClick={InstructionPageHks.handleCheck}
                  icon={{ type: "trailing", icon: <RightArrowSvgIcon /> }}
                  hierarchy={{
                    type: "primary",
                    buttonText: InstructionPageHks.getButtonText(),
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
