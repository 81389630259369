import { useContext } from "react";
import constants from "../../constants";
import AlertContext from "../../context/AlertContext";
import StudentTestContainerHooks from "./StudentTestContainerHooks";
import StudentTestPageCalcStatsHooks from "./StudentTestPageCalcStatsHooks";
import { StudentTestPageContext } from "../../context/StudentTestPageContext";
import StudentTestPageQuestionUpdateHooks from "./StudentTestPageQuestionUpdateHooks";

export default function StudentTestBodyPageHooks() {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const { setFilters } = StudentTestContainerHooks();
  const { calcStats } = StudentTestPageCalcStatsHooks();
  const { questionUpdate } = StudentTestPageQuestionUpdateHooks();

  const showTranscriptFunc = () => {
    StudentTestPageCxt.setShowTranscript(!StudentTestPageCxt.showTranscript);
  };

  const handleSingleVdoQnSubmit = () => {
    Alert.setSingleVdoSubmit(true);
  };

  /* Book mark questions */

  const showAnswer = () => {
    StudentTestPageCxt.setShowSaqLaqAns(!StudentTestPageCxt.showSaqLaqAns);
  };

  /* change the questions view list and grid */

  const changeQuestionVisible = (visible) => {
    if (visible === "single") {
      if (Alert.questionContainerSingle === false) {
        Alert.updateQuestionContainerSingle(true);
      }
    } else if (visible === "multiple") {
      StudentTestPageCxt.setShowSaqLaqAns(false);
      Alert.comingObj.visible = Alert.comingObj.previsited;

      if (Alert.questionContainerSingle === true) {
        Alert.updateQuestionContainerSingle(false);
      }
    }
  };

  const backToHomePage = () => {
    Alert.isAdmin
      ? Alert.navigate(`/batches/${sessionStorage.getItem("abatchid")}/report`)
      : Alert.backupTestData.mockuse
      ? Alert.navigate("main/mock/results")
      : Alert.navigate("/home");

    if (StudentTestPageCxt.showTranscript)
      StudentTestPageCxt.setShowTranscript(!StudentTestPageCxt.showTranscript);
    if (StudentTestPageCxt.showSaqLaqAns)
      StudentTestPageCxt.setShowSaqLaqAns(!StudentTestPageCxt.showSaqLaqAns);

    if (Alert.backupTestData.testmode === constants.MODE.PRACTICE_MODE) {
      Alert.setLpData({});
    }
  };

  /* vdo qn getting the sectionname  */

  const getSectionName = (qnid) => {
    for (let sec of Alert.comingObj.sections) {
      if (sec.questions.find((que) => que.id === qnid)) {
        return sec.sectionname;
      }
    }

    return null;
  };

  /* vdo qn getting the qn no  */

  const getQuestionNo = (qnid) => {
    for (let sec of Alert.comingObj.sections) {
      const questionIndex = sec.questions.findIndex((que) => que.id === qnid);

      if (questionIndex !== -1) {
        return questionIndex + 1;
      }
    }

    return null;
  };

  /* move to previous question */

  const traversePrevious = () => {
    let tempQueInd = Alert.queInd;
    let tempSecInd = Alert.secInd;

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === -1
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "not";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "not";
    }

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === 0
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "completed";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "completed";
    }

    if (Alert.queLen > 1 && Alert.queInd > 0) {
      tempQueInd--;

      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;
      Alert.setQueInd(Alert.queInd - 1);
      Alert.setNextButton(true);
    } else {
      tempSecInd--;
      Alert.setSecInd(Alert.secInd - 1);
      Alert.setNextButton(true);
      tempQueInd = Alert.comingObj.sections[tempSecInd].questions.length - 1;

      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;
      Alert.setQueInd(tempQueInd);
    }

    if (tempSecInd === 0 && tempQueInd === 0) {
      Alert.setPrevButton(false);
    }

    questionUpdate(tempSecInd, tempQueInd, Alert.comingObj);

    if (
      Alert.mcqAndDesTestAndLpId.status === constants.PROGRESS_STATUS.EVALUATED
    ) {
      setFilters(null);
    }
  };

  /* move to next question */

  const traverseNext = (key) => {
    let tempSecInd = Alert.secInd;
    let tempQueInd = Alert.queInd;
    Alert.PractSingleQnAns.current = false;
    Alert.PractSingleQnId.current = "";

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === -1
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "not";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "not";
    }

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === 0
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "completed";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "completed";
    }

    if (
      Alert.comingObj.sections[tempSecInd].questions.length ===
        tempQueInd + 1 &&
      Alert.comingObj.sections?.length >= tempSecInd + 1
    ) {
      tempSecInd++;
      tempQueInd = 0;

      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;

      questionUpdate(tempSecInd, tempQueInd, Alert.comingObj);
      Alert.setPrevButton(true);
    } else {
      tempQueInd++;

      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;

      questionUpdate(tempSecInd, tempQueInd, Alert.comingObj);
      Alert.setPrevButton(true);
    }

    if (
      Alert.comingObj.sections?.length === tempSecInd + 1 &&
      Alert.comingObj.sections[tempSecInd].questions.length === tempQueInd + 1
    ) {
      Alert.setPrevButton(true);
      Alert.setNextButton(false);
    }

    if (
      Alert.mcqAndDesTestAndLpId.status === constants.PROGRESS_STATUS.EVALUATED
    ) {
      setFilters(null);
    }

    Alert.setSecInd(tempSecInd);
    Alert.setQueInd(tempQueInd);
  };

  /* collect score */

  const collectScores = (qid, scor, maxscore, e) => {
    if (isNaN(scor) === false) {
      let score = Number(scor);
      let tScore = StudentTestPageCxt.currentScore;
      let tempScores = Alert.descScores;

      tempScores = { ...tempScores, [qid]: score };

      let tempErrors = StudentTestPageCxt.scoreErrors;

      if (score > maxscore || score === "" || score < 0) {
        if (Object.keys(tempErrors).length > 0) {
          StudentTestPageCxt.setHasScoreErrors(true);
        } else {
          StudentTestPageCxt.setHasScoreErrors(false);
        }

        if (score === "") {
          if (Object.keys(tempScores).map(Number)?.includes(qid)) {
            tScore = tScore - Alert.tsCoBkup[qid];

            if (Alert.ansStats.evalids?.includes(parseInt(qid))) {
              tScore = tScore + Alert.ansStats.idscore[qid];
            }
          } else {
            if (Alert.ansStats.evalids?.includes(parseInt(qid))) {
              tScore = tScore + Alert.ansStats.idscore[qid];
            }
          }

          Alert.setDescScores(tempScores);
          delete tempScores[qid];

          tempErrors = { ...tempErrors, [qid]: true };
          StudentTestPageCxt.setScoreErrors(tempErrors);
        }

        if (score > maxscore) {
          tempScores = { ...tempScores, [qid]: maxscore };
          Alert.setDescScores(tempScores);

          if (Object.keys(Alert.tsCoBkup).map(Number)?.includes(qid)) {
            tScore = tScore - Alert.tsCoBkup[qid];
          } else if (Alert.ansStats.evalids?.includes(Number(qid))) {
            tScore = tScore - Alert.ansStats.idscore[qid];
          }

          tScore = tScore + maxscore;
          Alert.setDescScores(tempScores);
        }

        if (score < 0) {
          tempScores = { ...tempScores, [qid]: 0 };
          Alert.setDescScores(tempScores);
        }
      } else {
        if (tempErrors.hasOwnProperty(qid)) {
          delete tempErrors[qid];
        }

        if (Object.keys(tempScores).map(Number)?.includes(qid)) {
          if (Object.keys(Alert.tsCoBkup).map(Number)?.includes(qid)) {
            tScore = tScore - Alert.tsCoBkup[qid];
          } else if (Alert.ansStats?.evalids?.includes(Number(qid))) {
            tScore = tScore - Alert.ansStats.idscore[qid];
          }

          tScore = tScore + score;
        } else {
          if (Alert.ansStats.evalids?.includes(parseInt(qid))) {
            tScore = tScore - Alert.ansStats.idscore[qid];
            tScore = tScore + score;
          } else {
            tScore = tScore + score;
          }
        }

        Alert.setDescScores(tempScores);
      }

      StudentTestPageCxt.setCurrentScore(tScore);
      StudentTestPageCxt.setEvalComp(true);

      for (const property in tempScores) {
        if (tempScores[property] == null) {
          StudentTestPageCxt.setEvalComp(false);
        }
      }

      Alert.setTsCoBkup(tempScores);

      for (let i = 0; i < Alert.comingObj.sections?.length; i++) {
        for (let j = 0; j < Alert.comingObj.sections[i].questions.length; j++) {
          if (
            Alert.comingObj.sections[i].questions[j].answer === "" &&
            Alert.comingObj.sections[i].questions[j].id === qid
          ) {
            StudentTestPageCxt.setGiveZeros(false);
            break;
          }
        }
      }
    }
  };

  /* Collect ans and showing error msg */

  /* change section  */

  const handleSecChange = (event) => {
    let newEve;

    if (event.target.value === "all") {
      newEve = 0;
      StudentTestPageCxt.setPrevSec(false);
      StudentTestPageCxt.setNextSec(true);
    } else {
      newEve = event.target.value;
    }

    StudentTestPageCxt.setSelSec(event.target.value);
    StudentTestPageCxt.setSectionObj(Alert.comingObj.sections[newEve]);

    if (event.target.value === 0) {
      if (Alert.comingObj.sections.length === 1) {
        StudentTestPageCxt.setPrevSec(false);
        StudentTestPageCxt.setNextSec(false);
      } else {
        StudentTestPageCxt.setPrevSec(false);
        StudentTestPageCxt.setNextSec(true);
      }
    } else if (event.target.value === Alert.comingObj.sections?.length - 1) {
      StudentTestPageCxt.setPrevSec(true);
      StudentTestPageCxt.setNextSec(false);
    } else {
      if (Alert.comingObj.sections.length === 1) {
        StudentTestPageCxt.setPrevSec(false);
        StudentTestPageCxt.setNextSec(false);
      } else {
        StudentTestPageCxt.setPrevSec(true);
        StudentTestPageCxt.setNextSec(true);
      }
    }

    calcStats(Alert.comingObj, [event.target.value]);

    if (
      Alert.mcqAndDesTestAndLpId.status === constants.PROGRESS_STATUS.EVALUATED
    ) {
      setFilters(null);
    }
  };

  const startListening = () => {
    if (StudentTestPageCxt.recognitionRef.current) {
      StudentTestPageCxt.recognitionRef.current.start();
      StudentTestPageCxt.setListening(true);
    }
  };

  const stopListening = () => {
    if (StudentTestPageCxt.recognitionRef.current) {
      StudentTestPageCxt.recognitionRef.current.stop();
      StudentTestPageCxt.setListening(false);
    }
  };

  return {
    showAnswer,
    traverseNext,
    getQuestionNo,
    collectScores,
    stopListening,
    getSectionName,
    backToHomePage,
    startListening,
    handleSecChange,
    traversePrevious,
    showTranscriptFunc,
    changeQuestionVisible,
    handleSingleVdoQnSubmit,
  };
}
