import Webcam from "react-webcam";
import React, { useContext } from "react";
import constants from "../../../constants";
import AlertContext from "../../../context/AlertContext";
import InstructionPageHooks from "../../../Hooks/InstructionPageHooks";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";

export default function InstructionPageBody() {
  const Alert = useContext(AlertContext);

  const InstructionPageHks = InstructionPageHooks();
  const { handleWebCamError } = useCommonFunctionHooks();

  return (
    <div className="instructionPageBodyMainContainer">
      <ul className="instructionPageBodyUl">
        {(Alert.testData.libtype === constants.libTypeIds.ASSIGNMENT ||
          Alert.testData.proctored !== constants.PROCTORING_MODE.NONE) &&
        Alert.testData.testtype !== constants.libTypeIds.CODING_CHALLENGE ? (
          <>
            <li>
              Ensure you are alone in the room and do not use any mobile device
              while taking this test.
            </li>
            <li>
              Please concentrate on the screen, your eye moments/face moments
              are being captured and this will effect your overall confidence
              score. A low confidence score will indicate plagiarism and system
              will flag your test.
            </li>
            <li>
              Ensure you do not switch tabs or use multiple screens during the
              test. Any attempt to switch tabs will be detected, and repeated
              tab switches will result in your test being automatically
              submitted.
            </li>
            {(Alert.testData.ainterview || Alert.testData.videoqn === 1) && (
              <>
                <li>
                  The countdown timer in the top right corner shows the
                  remaining time for the complete test.
                </li>
                <li>
                  When the timer reaches zero, the test will automatically end
                  and be submitted.
                </li>
              </>
            )}
            {Alert.testData.ainterview ? (
              <>
                <li>
                  Respond thoughtfully to each question presented on the screen.
                  <b> Do not read the question loud.</b>
                </li>
                <li>
                  To move to the next question, choose the 'Next' button. You
                  can take a few seconds between each question, but be aware of
                  the overall timer. Once you click 'Next' the question will be
                  displayed and the recording starts.
                </li>
                <li>
                  Once you've answered the final question, select the 'Finish'
                  button to complete the interview.
                </li>
                <li>
                  Against each question, there is a time limit as shown on the
                  chat pop-up.
                </li>
              </>
            ) : Alert.testData.videoqn === 1 ? (
              <>
                <li>
                  Once you've reviewed the question, click the 'Next' button to
                  proceed. Recording begins as soon as the question is
                  displayed.
                </li>
                <li>
                  Each question has a time limit, which will be displayed
                  alongside the question on the screen. Ensure your response is
                  within this time frame.
                </li>
                <li>
                  After answering the final question, click the 'Submit' button
                  to complete the video assessment.
                </li>
              </>
            ) : Alert.testData.single ? (
              <>
                <li>
                  Competitive Coding helps you practice a specific topic of
                  coding to ensure you master it. So, you can attempt this
                  specific topic multiple times until you feel confident.
                </li>
                <li>
                  You need to pass the all test cases given to receive a full
                  score. The evaluation will be against hidden test cases and
                  not the once displayed or you to test.
                </li>
                <li>
                  Make sure your code is robust enough to handle edge cases and
                  unexpected inputs.
                </li>
              </>
            ) : (
              <>
                <li>
                  You can revisit the question at any time by using the "View
                  Question" button for reference.
                </li>
                <li>Do not copy or paste code from external sources.</li>
                <li>
                  We provide external links to Tutorials point, Java T point and
                  W3 Schools. You can refer these websites by clicking on the
                  icon on the right.
                </li>
                <li>
                  Your progress is automatically saved, so you can focus on the
                  task without worrying about losing your work.
                </li>
              </>
            )}
          </>
        ) : Alert.testData.testtype ===
          constants.libTypeIds.CODING_CHALLENGE ? (
          <div className="instructionPageBodyUlForCC">
            <li>
              The coding challenge is proctored, an invigilator will be
              monitoring your activity throughout the test.
            </li>
            <li>
              The countdown timer in the top right displays the remaining time
              available.
            </li>
            <li>
              When the countdown timer reaches zero, the test will be
              auto-submitted.
            </li>

            <li>
              If you switch tabs from this window, the assignment will be auto
              submitted. Every time you do this, your marks against this
              challenge will be reduced.
            </li>
            <li>
              The next time you engage in this challenge, you will be presented
              with a problem of similar level of difficulty to solve. You will
              not encounter the same problem again as it will be a fresh
              opportunity to test your skills.
            </li>
            <li>
              If you exhaust the challenges under this category of similar
              difficulty, your account will be locked and your score against
              this will be marked as "0".
            </li>
            <li>
              If you continue navigating away from this page multiple times,
              your account will be locked.
            </li>
          </div>
        ) : (
          <>
            <li>
              Each question will appear one at a time on the screen. Read
              carefully and answer thoughtfully to maximize your practice.
            </li>
            <li>
              Once you select an answer, it will be validated to determine
              whether it is correct or incorrect.
            </li>
            <li>Use the 'Next' button to move to the next question.</li>
            <li>
              If you pass the minimum criteria for this Assessment, you can move
              to the next section of the Learning Path, otherwise you need to
              retake this assessment until you meet the minimum criteria.
            </li>
          </>
        )}
      </ul>
      {(Alert.testData.videoqn === 1 ||
        (Alert.testData.testmode === constants.MODE.TEST_MODE &&
          Alert.testData.proctored === constants.PROCTORING_MODE.TAB_CAM)) && (
        <Webcam
          screenshotFormat="image/jpeg"
          ref={InstructionPageHks.picRef}
          className="instructionPageWebCam"
          onUserMediaError={handleWebCamError}
          videoConstraints={InstructionPageHks.videoConstraints}
        />
      )}
    </div>
  );
}
