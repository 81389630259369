import React, { useRef, useEffect, useContext } from "react";
import { StudentTestPageContext } from "../../../context/StudentTestPageContext";

export default function StudentTestVideoPreview({ stream, isBlurred }) {
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const videoRef = useRef(null);

  // useEffect(() => {
  //   const SpeechRecognition =
  //     window.SpeechRecognition || window.webkitSpeechRecognition;

  //   if (!SpeechRecognition) {
  //     console.error("SpeechRecognition API is not supported in this browser.");
  //     return;
  //   }

  //   const recognition = new SpeechRecognition();

  //   recognition.continuous = true;
  //   recognition.interimResults = true;
  //   recognition.lang = "en-US";

  //   recognition.addEventListener("result", (event) => {
  //     const transcriptArray = Array.from(event.results)
  //       .map((result) => result[0].transcript)
  //       .join("");

  //     StudentTestPageCxt.transcript.current = transcriptArray;
  //   });

  //   recognition.addEventListener("end", () => {
  //     if (StudentTestPageCxt.listening) {
  //       recognition.start();
  //     }
  //   });

  //   StudentTestPageCxt.recognitionRef.current = recognition;

  //   return () => {
  //     recognition.stop();
  //     recognition.removeEventListener("end", () => {});
  //     recognition.removeEventListener("result", () => {});
  //   };
  // }, []);
  
  useEffect(() => {
    if (
      stream &&
      videoRef.current &&
      videoRef.current.srcObject?.active !== stream?.active
    ) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  return (
    <div>
      <video
        autoPlay
        ref={videoRef}
        className={`video-recorder ${isBlurred ? "blurred" : ""}`}
      />
      {/* <div className="studentTestVideoTranscript">
        {StudentTestPageCxt.transcript.current}
      </div> */}
    </div>
  );
}
