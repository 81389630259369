import AlertContext from "./AlertContext";
import { getDataFromStorage } from "../util";
import React, { useRef, createContext, useState, useContext } from "react";

export const StudentTestPageContext = createContext();

export default function StudentTestPageProvider({ children }) {
  const Alert = useContext(AlertContext);

  let viewTest = Alert.mcqAndDesTestAndLpId.viewTest;
  let isStudent = getDataFromStorage("role") === "learner";
  let isTrainer = getDataFromStorage("role") === "trainer";
  let isLearner = getDataFromStorage("role") === "learner";

  const defaultShowButton = { next: false, last: false };
  const defaultTranscript = "Your response will appear here...";

  const defaultIsSpeaking = {
    question: false,
    feedback: false,
  };

  const videoQnNo = useRef(0);
  const textRef = useRef(null);
  const videoRef = useRef(null);
  const editorRef = useRef(null);
  const questionRefs = useRef([]);
  const showSubmitBtn = useRef(false);
  const recognitionRef = useRef(null);
  const transcript = useRef(defaultTranscript);

  const [sRender, setSRender] = useState(0);
  const [selSec, setSelSec] = useState("all");
  const [secIndex, setSecIndex] = useState(0);
  const [prevSec, setPrevSec] = useState(false);
  const [nextSec, setNextSec] = useState(false);
  const [fSections, setFSections] = useState([]);
  const [mulRevch, setMulRevch] = useState(false);
  const [evalComp, setEvalComp] = useState(false);
  const [showReview, setShowReview] = useState([]);
  const [sectionObj, setSectionObj] = useState({});
  const [giveZeros, setGiveZeros] = useState(false);
  const [listening, setListening] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [scoreErrors, setScoreErrors] = useState({});
  const [startVideo, setStartVideo] = useState(false);
  const [curObj, setCurObj] = useState({ review: -1 });
  const [cameraPopup, setCameraPopup] = useState(false);
  const [currentQnData, setCurrentQnData] = useState({});
  const [currentQnIndex, setCurrentQnIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showSaqLaqAns, setShowSaqLaqAns] = useState(true);
  const [disableNxtBtn, setDisableNxtBtn] = useState(false);
  const [showtransition, setshowtransition] = useState(false);
  const [hasScoreErrors, setHasScoreErrors] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);
  // const [singleVdoSubmit, setSingleVdoSubmit] = useState(false);
  const [questionIdOffError, setQuestionIdOffError] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(defaultIsSpeaking);
  const [showButton, setShowButton] = useState(defaultShowButton);
  const [currentScore, setCurrentScore] = useState(Alert.totalLearnerScore);

  let [viewFilters, setViewFilters] = useState({
    tq: true,
    qa: false,
    na: false,
    ca: false,
    wa: false,
    ea: false,
    me: false,
    ha: false,
  });

  const [videoQuestionAttempt, setVideoQuestionAttempt] = useState(
    Alert.comingObj.videoqn === 1
  );

  const [vdoQnTimelimit, setVdoQnTimelimit] = useState(
    Alert.comingObj.videoqn === 1
      ? Alert.videoQn.current?.filter((data) => data?.selected)[0]?.qnduration
      : 0
  );

  return (
    <StudentTestPageContext.Provider
      value={{
        curObj,
        selSec,
        textRef,
        sRender,
        prevSec,
        nextSec,
        videoRef,
        viewTest,
        mulRevch,
        secIndex,
        evalComp,
        editorRef,
        setCurObj,
        giveZeros,
        fSections,
        setSelSec,
        isStudent,
        isLearner,
        apiLoaded,
        videoQnNo,
        isTrainer,
        listening,
        showTimer,
        isSpeaking,
        sectionObj,
        setNextSec,
        setPrevSec,
        showReview,
        setSRender,
        startVideo,
        showButton,
        transcript,
        cameraPopup,
        scoreErrors,
        setEvalComp,
        setSecIndex,
        setMulRevch,
        viewFilters,
        questionRefs,
        setListening,
        errorMessage,
        setShowTimer,
        setApiLoaded,
        setGiveZeros,
        setFSections,
        currentScore,
        disableNxtBtn,
        setIsSpeaking,
        showSaqLaqAns,
        showSubmitBtn,
        setSectionObj,
        setShowReview,
        setShowButton,
        setStartVideo,
        currentQnData,
        currentQnIndex,
        recognitionRef,
        showTranscript,
        hasScoreErrors,
        setCameraPopup,
        setViewFilters,
        showtransition,
        setScoreErrors,
        vdoQnTimelimit,
        setErrorMessage,
        setCurrentScore,
        // singleVdoSubmit,
        setShowSaqLaqAns,
        setDisableNxtBtn,
        setCurrentQnData,
        setHasScoreErrors,
        setCurrentQnIndex,
        setshowtransition,
        setShowTranscript,
        setVdoQnTimelimit,
        defaultShowButton,
        defaultIsSpeaking,
        defaultTranscript,
        // setSingleVdoSubmit,
        questionIdOffError,
        videoQuestionAttempt,
        setQuestionIdOffError,
        setVideoQuestionAttempt,
      }}
    >
      {children}
    </StudentTestPageContext.Provider>
  );
}
